import { asLink } from '@prismicio/client';
import Link from 'next/link';
import clsx from 'clsx';
import { memo } from 'react';
import { NavigationItem } from './HeaderDropdown';

interface HeaderLinkComponentProps {
  withCustomLink: boolean;
  navItem: NavigationItem;
  children: React.ReactNode;
  isLarge?: boolean;
  [key: string]: any;
}
const types = {
  'service': '/services'
}
const defaultLocale = "en"
const HeaderLinkComponent = ({
  withCustomLink,
  navItem,
  children,
  isLarge = false,
  onClick = () => {},
  ...props
}: HeaderLinkComponentProps) => {
  return withCustomLink ? (
    asLink(navItem.link) ? <Link target={navItem.link_target} locale={defaultLocale} href={asLink(navItem.link)} className='block' >
      <span
        className={clsx('[ flex gap-2 ][ rounded-lg ][ text-white ][ lg:hover:bg-black transition ]', {
          '[ py-2 px-1 lg:p-2.5 my-4 ]': isLarge,
          '[ pr-2 py-2 lg:p-2.5 ]': !isLarge,
        })}
        onClick={onClick}
      >
        {children}
      </span>
    </Link>: <Link target={navItem.link_target} locale={defaultLocale} href={types[navItem.link.type] ? `${types[navItem.link.type]}/${navItem.link.uid}` : '/'} className='block' >
      <span
        className={clsx('[ flex gap-2 ][ rounded-lg ][ text-white ][ lg:hover:bg-black transition ]', {
          '[ py-2 px-1 lg:p-2.5 my-4 ]': isLarge,
          '[ pr-2 py-2 lg:p-2.5 ]': !isLarge,
        })}
        onClick={onClick}
      >
        {children}
      </span>
    </Link>
  ) : (
    <Link
      className={clsx('[ flex gap-2 ][ rounded-lg ][ text-white ][ lg:hover:bg-black transition ]', {
        '[ py-2 px-1 lg:p-2.5 my-4 ]': isLarge,
        '[ p-2.5 ]': !isLarge,
      })}

      href={navItem.link?.url}
      target={navItem.link_target}
    >
      {children}
    </Link>
  );
};

HeaderLinkComponent.displayName = 'HeaderLinkComponent';

export default memo(HeaderLinkComponent);
