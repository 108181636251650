import { Fragment, memo, useState, useRef, useEffect, startTransition } from 'react';
import clsx from 'clsx';
import { CommonButton } from 'ui/components/CommonButton';
import { asLink } from '@prismicio/client';
import Link from 'next/link';
import ExpandLess from 'ui/svgs/library/ExpandLess';
import { constants } from 'ui/utils/constants';
import Search from 'ui/components/Search';
import { SearchResponse } from '../../../../apps/web/service/elasticsearch';
import { ArrowDown } from './HeaderDropdown';
import HeaderLinkComponent from './HeaderLinkComponent';

export const HeaderCTAs = memo(
  ({
    headerData,
    setOpenGetStarted,
    className,
    handleCloseNav = () => {},
    handleOnSearchSubmit,
    handleGetQuickResults,
    showSearch,
  }: {
    headerData: any;
    setOpenGetStarted: (open: boolean) => void;
    className?: string;
    showSearch: boolean;
    handleCloseNav?: () => void;
    handleOnSearchSubmit?: (q: string) => void;
    handleGetQuickResults?: (query: string) => Promise<SearchResponse>;
  }) => {
    const [showCTAOptions, setShowCTAOptions] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setShowCTAOptions(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [handleCloseNav]);

    const handleOpenGetStarted = () => {
      setOpenGetStarted(true);
    };
    return (
      <div
        ref={containerRef}
        className={clsx(
          '[ flex flex-col justify-end lg:items-center lg:flex-row gap-2 xl:gap-4 ]',
          '[ px-4 pb-32 lg:p-0 ]',
          '[ h-full lg:h-auto ]',
          className,
        )}
      >
        <Search
          onSubmit={handleOnSearchSubmit}
          getQuickResults={handleGetQuickResults}
          className={clsx({
            hidden: !showSearch,
          })}
        />
        {headerData.items
          ?.filter(item => item.alignment_type === 'CTA')
          .map((item, i) => {
            const { text } = item;
            const isMenu = item.type === 'Menu';
            const isCTA = item.type === 'CTA';
            const id = `top-nav-${text.toLowerCase().split(' ').join('-')}`;
            return (
              <Fragment key={`cta-${i}`}>
                <div
                  className={clsx('[ rounded-lg ]')}
                  role="presentation"
                  id={`top_nav_wrapper_${text.toLowerCase().split(' ').join('_')}`}
                  {...(isMenu
                    ? {
                        onClick: () => {
                          startTransition(() => {
                            setShowCTAOptions(val => !val);
                          });
                        },
                      }
                    : {})}
                >
                  {isCTA && (
                    <>
                      <CommonButton
                        onClick={handleOpenGetStarted}
                        color="blue"
                        id={id}
                        className={clsx('[ w-full lg:w-auto ]', '[ hidden lg:block ]')}
                      >
                        {text}
                      </CommonButton>
                      <CommonButton
                        href="/get-started"
                        color="blue"
                        id={id}
                        className={clsx('[ w-full lg:w-auto ]', '[ block lg:hidden ]')}
                      >
                        {text}
                      </CommonButton>
                    </>
                  )}

                  {isMenu && (
                    <button
                      id={id}
                      className={clsx(
                        '[ flex items-center justify-center ]',
                        '[ font-medium ]',
                        '[ p-2 lg:py-2 lg:px-4 ]',
                        '[ text-white ]',
                        '[ rounded-lg ]',
                        '[ lg:text-14 xl:text-16 ]',
                        '[ w-full lg:w-auto ]',
                        '[ border border-solid border-white lg:border-0 ]',
                        '[ hover:border-white hover:text-white hover:bg-transparent lg:hover:bg-gray-20 transition ]',
                        '[ lg:focus:bg-gray-20 ]',
                      )}
                    >
                      {text} <ArrowDown className="[ hidden lg:block ] [ pointer-events-none ]" />
                    </button>
                  )}

                  {!isCTA && !isMenu && (
                    <Link
                      href={asLink(item.link)}
                      id={id}
                      className={clsx(
                        '[ flex items-center justify-center ]',
                        '[ font-semibold ]',
                        '[ p-2 lg:py-2 lg:px-4 ]',
                        '[ text-white ]',
                        '[ rounded-lg ]',
                        '[ lg:text-14 xl:text-16 ]',
                        '[ w-full lg:w-auto ]',
                        '[ border border-solid border-white lg:border-0 ]',
                        '[ hover:border-white hover:text-white hover:bg-transparent lg:hover:bg-gray-20 transition ]',
                      )}
                    >
                      {text}
                    </Link>
                  )}
                  {/* Render dropdown for login on desktop only */}
                  {isMenu && (
                    <div
                      className={clsx(
                        '[ absolute ] [ bg-white text-black ] [ hidden lg:flex ]',
                        '[ bg-gray-20 lg:bg-gray-0/85 lg:backdrop-blur-md ]',
                        '[ lg:fixed lg:top-[72px] lg:z-1001 ]',
                        '[ lg:justify-center ]',
                        '[ pl-6 lg:pl-0 ]',
                        '[ lg:rounded-b-xl ]',
                        {
                          '[ flex ]': showCTAOptions,
                          '[ lg:hidden ]': !showCTAOptions,
                        },
                      )}
                    >
                      <div className="[ max-w-72 ][ pr-2 py-2 lg:p-2 ][ lg:text-14 xl:text-16 ]">
                        {item.navigation_list.data?.navigation_links.map((navLink, i) => {
                          return (
                            <Fragment key={`dropdown-${i}`}>
                              {navLink.link_to_document.data.navigation_items.map((navItem, i) => {
                                return (
                                  <Fragment key={`dropdown-${navItem.label}-${i}`}>
                                    <HeaderLinkComponent
                                      withCustomLink={!navItem.link?.url?.includes(constants.BLOG_URL_PART)}
                                      navItem={navItem}
                                    >
                                      <div>
                                        <div className="[ font-semibold ][ lg:text-14 xl:text-16 ]">
                                          {navItem.label}
                                        </div>
                                        {navItem.description && (
                                          <p className="[ text-12 mb-0 ]">{navItem.description}</p>
                                        )}
                                      </div>
                                    </HeaderLinkComponent>
                                  </Fragment>
                                );
                              })}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                {/* Render dropdown for login on mobile only */}
                {isMenu && (
                  <div
                    className={clsx(
                      '[ lg:hidden ][ bg-gray-10 ][ absolute top-0 right-0 ]',
                      '[ w-full h-full max-w-82.5 ]',
                      '[ overflow-y-scroll overflow-x-hidden scrollbar-0 ]',
                      '[ transition-all ]',
                      {
                        '[ translate-x-0 visible ]': showCTAOptions,
                        '[ translate-x-full invisible ]': !showCTAOptions,
                      },
                    )}
                  >
                    <div className="[ px-4 py-5 ][ flex items-center justify-center ][ border-b border-solid border-gray-40 ][ relative ]">
                      <div
                        onClick={() => setShowCTAOptions(false)}
                        className="[ flex items-center ][ absolute top-1/2 left-2 -translate-y-1/2 ][ text-white font-medium ][ cursor-pointer ]"
                      >
                        <ExpandLess className="[ -rotate-90 ] [ pointer-events-none ]" /> Back
                      </div>
                      <div className="[ text-24 text-white font-medium ]">Login</div>
                    </div>
                    {item.navigation_list.data.navigation_links.map((navLink, index) => {
                      return (
                        <div className="[ py-5 px-2.5 space-y-6 ]" key={index}>
                          {navLink.link_to_document.data.navigation_items.map(navItem => {
                            return (
                              <div
                                key={`nav-item-${navItem.label}`}
                                onClick={() => {
                                  handleCloseNav();
                                }}
                              >
                                <CommonButton
                                  appearance="outline"
                                  color="white"
                                  id={`top-nav-${navItem.label.toLowerCase().split(' ').join('-')}`}
                                  href={asLink(navItem.link)}
                                  target={navItem.link_target}
                                  className="[ w-full ]"
                                >
                                  {navItem.label}
                                </CommonButton>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>
    );
  },
);

HeaderCTAs.displayName = 'HeaderCTAs';
