import * as React from 'react';
import { CustomLink } from 'ui/components/CustomLink';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { asLink } from '@prismicio/client';
import Link from 'next/link';
import Search from 'ui/components/Search';
import { AppContext } from 'ui/utils/app-context';
import { isNonProdEnvironment } from 'ui/utils/helpers/env-helper';
import { useFeature } from '@growthbook/growthbook-react';
import GetStartedModal from '../GetStarted/GetStartedModal';
import { getSearchResults } from '../../../../apps/web/service/elasticsearch';
import { HeaderDropdown } from './HeaderDropdown';
import { HeaderCTAs } from './HeaderCTAs';
import useHeaderData from './useHeaderData';

// Note: Expand this mapping as we add more countries to growthbook
const countryMapping = {
  US: 'united states',
};

export const HeaderV2 = ({ data }: { data: any }) => {
  const router = useRouter();
  const ctx = React.useContext(AppContext);
  const headerData: any = useHeaderData(data);
  const { value: searchFeatureConfig } = useFeature('search-rollout-geo');

  const [navOpen, setNavOpen] = React.useState(false);
  const [openGetStarted, setOpenGetStarted] = React.useState(false);
  const [hydrated, setHydrated] = React.useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
  const navRef = React.useRef<HTMLDivElement>(null);

  const handleOnSearchSubmit = React.useCallback(
    (q: string) => {
      router.push({ pathname: '/search', query: { q } });
    },
    [router],
  );

  const handleGetQuickResults = React.useCallback((q: string) => {
    return getSearchResults(q, { size: 3 });
  }, []);

  const showSearch = React.useMemo(() => {
    if (isNonProdEnvironment()) {
      return true;
    }
    return (
      searchFeatureConfig && searchFeatureConfig.allowedCountries.includes(countryMapping[ctx.impervaUserInfo?.country])
    );
  }, [searchFeatureConfig, ctx.impervaUserInfo?.country]);

  const handleCloseNav = React.useCallback(() => {
    setNavOpen(false);
  }, []);

  const handleCloseModal = React.useCallback(() => setOpenGetStarted(false), []);

  React.useEffect(() => {
    setHydrated(true);
    const handleClickOutside = (event: MouseEvent) => {
      if (navRef.current && !navRef.current.contains(event.target as Node)) {
        if (window.innerWidth < 1024) {
          // Check if the screen width is less than 1024px (mobile)
          setNavOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navRef]);
  const onDropDownOpen = React.useCallback(setIsDropDownOpen, [setIsDropDownOpen]);
  return (
    <header
      className={clsx(
        'site-header [ fixed top-0 left-0 z-1001 ]',
        '[ bg-gray-0 h-18 w-full text-white ]',
        '[ flex justify-center items-center ]',
        '[ py-3 px-5 lg:px-14 ]',
        {
          'drop-shadow': !isDropDownOpen,
        },
      )}
    >
      <div ref={navRef} className={clsx('[ max-w-8xl w-full ]', '[ flex justify-between items-center gap-6 ]')}>
        <div className={clsx('[ flex items-center ]', '[ space-x-6 ]')}>
          <CustomLink href="/" prefetch={false}>
            <a className="[ leading-none ][ shrink-0 ]" id="top-nav-cta-turing-logo">
              <img loading="eager" alt="logo" src={'/assets/Turing-Wordmark_White.svg'} width={124} height={45} />
            </a>
          </CustomLink>
          <div
            className={clsx(
              '[ bg-gray-10 lg:bg-transparent ]',
              '[ grid lg:flex lg:justify-between lg:grow ]',
              '[ transition-all ]',
              '[ absolute top-full right-0 z-1001 lg:static lg:top-auto lg:right-auto ]',
              '[ w-full h-[calc(100vh-72px)] max-w-82.5 lg:h-auto lg:max-w-none ]',
              '[ scrollbar-0 overflow-y-scroll overflow-x-hidden lg:overflow-y-visible ]',
              '[ lg:visible lg:transform-none ]',
              {
                'visible translate-x-0': navOpen,
                'invisible translate-x-full': !navOpen,
                '!hidden': !hydrated,
              },
            )}
          >
            <nav className="lg:flex lg:space-x-1">
              {headerData.items
                ?.filter(item => item.alignment_type === 'Navigation')
                .map((item, i) => {
                  const { text } = item;
                  const isMenu = item.type === 'Menu';
                  const id = `top-nav-${text.toLowerCase().split(' ').join('-')}`;
                  return (
                    <React.Fragment key={i}>
                      {isMenu ? (
                        <HeaderDropdown
                          text={text}
                          item={item}
                          id={id}
                          handleCloseNav={handleCloseNav}
                          onDropDownOpen={onDropDownOpen}
                        />
                      ) : (
                        <Link
                          id={id}
                          href={asLink(item.link)}
                          className={clsx(
                            '[ font-normal lg:text-14 xl:text-16 text-white ]',
                            '[ px-4 py-5 lg:p-2 ]',
                            '[ hover:bg-gray-20 ]',
                            '[ rounded-lg ]',
                          )}
                          onClick={handleCloseNav}
                        >
                          {text}
                        </Link>
                      )}
                    </React.Fragment>
                  );
                })}
            </nav>
            <HeaderCTAs
              headerData={headerData}
              setOpenGetStarted={setOpenGetStarted}
              className="lg:hidden"
              handleCloseNav={handleCloseNav}
              showSearch={false}
            />
          </div>
        </div>
        <HeaderCTAs
          headerData={headerData}
          setOpenGetStarted={setOpenGetStarted}
          className={clsx('hidden lg:flex', {
            '!hidden': !hydrated,
          })}
          handleOnSearchSubmit={handleOnSearchSubmit}
          handleGetQuickResults={handleGetQuickResults}
          showSearch={showSearch}
        />

        <div
          className={clsx('[ flex lg:hidden ]', {
            '!hidden': !hydrated,
          })}
        >
          <Search
            onSubmit={handleOnSearchSubmit}
            getQuickResults={handleGetQuickResults}
            className={clsx({
              hidden: !showSearch,
            })}
          />
          <div className="[ lg:hidden ]">
            {navOpen ? (
              <Image
                className="site-header__burger"
                src={'/img/Hamburger_close.svg'}
                onClick={() => setNavOpen(state => !state)}
                width={48}
                height={48}
                alt="Hamburger_close.svg"
                loading="eager"
              />
            ) : (
              <Image
                className="site-header__burger"
                src={'/img/Hamburger_menu.svg'}
                onClick={() => {
                  React.startTransition(() => {
                    setNavOpen(state => !state);
                  });
                }}
                width={48}
                height={48}
                alt="Hamburger_menu.svg"
              />
            )}
          </div>
        </div>
      </div>
      <GetStartedModal open={openGetStarted} onClose={handleCloseModal} />
    </header>
  );
};

export default HeaderV2;
